require('./bootstrap');

import AOS from 'aos';
AOS.init();

// Funciones
function go(pos){
	$("body,html").animate({ scrollTop: pos }, 200);
}
// Scroll Navbar
$(".go").click(function(e){
	e.preventDefault();
	var ida = $(this).attr('href');
	var pos = $(ida).offset().top;
	go(pos);
});

$(".gomov").click(function(e){
	e.preventDefault();
	var ida = $(this).attr('href');
	var pos = $(ida).offset().top;
	var offCanvasNavbar = $('#offCanvasNavbar');
	offCanvasNavbar.offcanvas('hide');
	offCanvasNavbar.on('hidden.bs.offcanvas', function(){
		go(pos);
	});
});